import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday';

export default class extends Controller {
  connect() {
    this.element.autocomplete = 'off';

    const picker = new Pikaday({
      field: this.element,
      format: 'D/M/YYYY',
      minDate: new Date(2016, 6, 20),
      maxDate: new Date(2025, 1, 1),
    });
  }
}

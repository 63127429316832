import Rails from '@rails/ujs';


Rails.start();

window.addEventListener('load', () => {
  document.body.classList.add('body--visible');
});
  
// Images - Imports every image from image folder
function importAll(r) {
  return r.keys().map(r);
}

// importAll(require.context('../../assets/images/.', true, /\.(png|gif|jpe?g|svg)$/));

var _print = window.print;
window.print = function() {
  alert('This will take you to the print page. Please select Save as PDF to download the file.');
  _print();
};

import { Controller } from "@hotwired/stimulus"

// Import TinyMCE
import tinymce from 'tinymce/tinymce'
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/colorpicker/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/contextmenu/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/noneditable/plugin';
import 'tinymce/plugins/spellchecker/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/textcolor/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/themes/modern/theme';

export default class extends Controller {

  static targets = ['input']

  initialize () {
    this.defaults = {
      height: 300,
      // mode: "textareas",
      content_css: false,
      skin: false,
      force_br_newlines : true,
      force_p_newlines : false,
      forced_root_block : '',
      plugins:"advlist anchor colorpicker code contextmenu fullscreen image link lists noneditable spellchecker textcolor wordcount visualblocks table",
      toolbar: [
        'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table | code',
        'forecolor backcolor',
      ],
      textcolor_map: [
        '000000',
        'Black',
        '993300',
        'Burnt orange',
        '333300',
        'Dark olive',
        '003300',
        'Dark green',
        '003366',
        'Dark azure',
        '000080',
        'Navy Blue',
        '333399',
        'Indigo',
        '333333',
        'Very dark gray',
        '800000',
        'Maroon',
        'FF6600',
        'Orange',
        '808000',
        'Olive',
        '008000',
        'Green',
        '008080',
        'Teal',
        '0000FF',
        'Blue',
        '666699',
        'Grayish blue',
        '808080',
        'Gray',
        'FF0000',
        'Red',
        'FF9900',
        'Amber',
        '99CC00',
        'Yellow green',
        '339966',
        'Sea green',
        '33CCCC',
        'Turquoise',
        '3366FF',
        'Royal blue',
        '800080',
        'Purple',
        '999999',
        'Medium gray',
        'FF00FF',
        'Magenta',
        'FFCC00',
        'Gold',
        'FFFF00',
        'Yellow',
        '00FF00',
        'Lime',
        '00FFFF',
        'Aqua',
        '00CCFF',
        'Sky blue',
        '993366',
        'Red violet',
        'FFFFFF',
        'White',
        'FF99CC',
        'Pink',
        'FFCC99',
        'Peach',
        'FFFF99',
        'Light yellow',
        'CCFFCC',
        'Pale green',
        'CCFFFF',
        'Pale cyan',
        '99CCFF',
        'Light sky blue',
        'CC99FF',
        'Plum',
      ],
    }
  }

  connect () {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}
